// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]7.0-rc-11407(a0863a6e3d)-C19/03/2025-09:42:57-B19/03/2025-09:44:51' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]7.0-rc-11407(a0863a6e3d)-C19/03/2025-09:42:57-B19/03/2025-09:44:51",
  branch: "master",
  latestTag: "7.0-rc",
  revCount: "11407",
  shortHash: "a0863a6e3d",
  longHash: "a0863a6e3df398e9d46e39ed177e23117b13491f",
  dateCommit: "19/03/2025-09:42:57",
  dateBuild: "19/03/2025-09:44:51",
  buildType: "Ansible",
  } ;
