import {ObjectDTO} from "../object-dto";
import {GefProviderDto} from "./gef-provider-dto";

export class GefConnectionDto extends ObjectDTO {

  gefProvider: GefProviderDto
  properties: any;
  jsonProperties: any;
  gefFluxList: String[]

  constructor() {
    super();
    this.actif = true;
  }
}
